<template>
    <a :href="href" :target="target"><slot></slot><font-awesome-icon :icon="['far', 'external-link-alt']" size="1x" class="ml-2" v-if="target==='_blank'"/></a>
</template>
<script>
export default {
    props: {
        href: {
            type: String,
        },
        target: {
            type: String,
        },
    },
};
</script>
